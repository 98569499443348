@import "~@ui/styles/tools";
@import "../../styles/variables";

.container {
    display: grid;
    gap: 10px;
    --columns-count: 6;
    grid-template-columns: repeat(var(--columns-count, 6), 1fr);

    &-skeleton {
        display: grid;
        gap: 10px;
        --columns-count: 3;
        grid-template-columns: repeat(var(--columns-count, 6), 1fr);
    }

    &.full {
        grid-template-columns: repeat(3, 1fr);

        @include media-desktop-md {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-tablet-portrait {
            grid-template-columns: 1fr;
        }
    }
}

.block {
    width: 100%;
    padding: 28px;
    background: $color-off-white;

    &.gray {
        background-color: #F6F7F8;
    }

    &-skeleton {
        width: 100%;
        background: $color-off-white;
        height: 156px;
    }
}

.block__third {
    grid-column: span 2 / span 6;

    @include media-tablet-portrait {
        grid-column: span 6 / span 6;
    }
}

.block__half {
    grid-column: span 3 / span 6;
}

.block__full {
    grid-column: span 6 / span 6;
}

.block__title {
    color: $color-title-lighter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    margin-bottom: 16px;
}

@include media-tablet {
    .block {
        padding: 20px 0;
    }

    .block__title {
        font-size: 20px;
    }
}

@include media-tablet-portrait {
    .container {
        --columns-count: 2;
    }

    .block__third {
        grid-column: span 1 / span 2;
    }

    .block__half,
    .block__full {
        grid-column: span 2 / span 2;
    }
}

@include media-mobile {

    .block__third,
    .block__half,
    .block__full  {
        grid-column: span 1 / span 1;
    }
}

@include media-mobile {
    .block {
        padding: 20px;
    }
}
